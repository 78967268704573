/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
.legacyInput,
.legacyStyleButton {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
.legacyStyleButton {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
.legacyStyleButton {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
html .legacyInput[type="button"], /* 1 */
.legacyInput[type="reset"],
.legacyInput[type="submit"],
.legacyStyleButton {
  appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
html .legacyInput[disabled],
.legacyStyleButton[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
.legacyInput::-moz-focus-inner,
.legacyStyleButton::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

.legacyInput {
  line-height: normal;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

.legacyInput[type='number']::-webkit-inner-spin-button,
.legacyInput[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */

.legacyInput[type='search'] {
  appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

.legacyInput[type='search']::-webkit-search-cancel-button,
.legacyInput[type='search']::-webkit-search-decoration {
  appearance: none;
}

@media not print {

  .legacyStyleButton,
  .legacyLi,
  .legacyDiv {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    align-items: stretch;
  }
}

/**
  * From _defaults.scss
  */
@media not print {

  .legacyInput {
    color: $dark-base;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  }
}

.legacyInput {
  @include font__bodyRegularMixin($color__Gray-01);

  background-color: $color__White;
  padding: 6px;
  border-radius: 4px;
  border: 1px $color__Gray-04 solid;
}

.legacyInput::-ms-clear {
  display: none;
}

.legacyInput::-webkit-outer-spin-button,
.legacyInput::-webkit-inner-spin-button {
  // display: none; <- Crashes Chrome on hover
  appearance: none;
  margin: 0; // <-- Apparently some margin are still there even though it's hidden
}

.legacyInput:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
}

.legacyInput::-webkit-outer-spin-button,
.legacyInput::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.legacyInput[type='time']::-webkit-calendar-picker-indicator {
  font-size: 14px;
}
