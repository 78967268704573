@font-face {
  font-family: Nunito;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('/Nunito-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nunito;
  src: local('Nunito ExtraBold'), local('Nunito-ExtraBold'),
    url('/Nunito-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
