// background

.background-transparent {
  background: transparent;
}

.background-transparent-grey {
  background: rgb(241 243 250 / 35%);
}

.background-white {
  background-color: #fff;
}

.background-depth {
  background-color: $color__Gray-06;
  box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 25%);
}

// Text

.text-center {
  text-align: center;
}

.ellipsis-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-preline {
  white-space: pre-line;
}

.color-primary {
  color: #0e75c2 !important;
}

.color-secondary {
  color: $color__secondary;
}

.color-warning {
  color: #d3302c !important;
}

.color-black {
  color: black;
}

.color-gray {
  color: $color__grey;
}

.color-dark-grey {
  color: #5f6a7c;
}

.color-inherit {
  color: inherit;
}

.color-white {
  color: white;
}

// Colors
.link-primary {
  @extend .color-primary;

  font-size: 1em;
}

.link-warning {
  @extend .color-warning;

  font-size: 1em;
}

.link-primary:hover,
.link-warning:hover {
  text-decoration: underline;
}

//flex

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center-self {
  align-self: center;
}

.flex-baseline-self {
  align-self: baseline;
}

.flex-start-self {
  align-self: flex-start;
}

.flex-end-self {
  align-self: flex-end;
}

.flex-stretch-self {
  align-self: stretch;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-items-center {
  align-items: center;
}
.flex-items-stretch {
  align-items: stretch;
}
.flex-items-start {
  align-items: start;
}

.flex-items-end {
  align-items: end;
}

.flex-end,
.flex-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-auto {
  flex: auto;
}

.flex-align-space-between {
  align-content: space-between;
}

.flex-align-baseline {
  align-items: baseline;
}

.flex-start,
.flex-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-no-grow {
  flex-grow: 0;
}

.flex-equal {
  flex: 1 0 0;
}

.flex-one {
  flex: 1;
}

.flex-four {
  flex: 4 1 auto;
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-min-content {
  flex-basis: min-content;
}

// Grid

.grid {
  display: grid;
}

@for $i from 0 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

// interactive
.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

// element
.self-center {
  margin: 0 auto;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-bottom {
  vertical-align: bottom;
}

.warning-border {
  border: 1px solid #dc4c66;
}

.warning-border-yellow {
  border: 1px solid $color__Warning_02;
}

.warning-border-blue {
  border: 1px solid $color__Info_05;
}

.success-border {
  border: 1px solid rgb(0 193 51);
}

.info-border {
  border: solid 1px #4773d4;
}

.dashed-border {
  border: 1px dashed #323a40;
}

.transparent-border {
  border: 1px solid transparent;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.border-bottom {
  border-bottom: 1px solid #e1e1e1;
}

.border-none {
  border: none;
}

.interactive {

  &:hover {
    background-color: #ebf0f1;
  }
}

.with-before-divider::before,
.with-after-divider::after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 20%;
  bottom: 20%;
  border-left: 1px solid $color__Gray-04;
}

.with-before-divider::before {
  left: 0;
}

.with-after-divider::after {
  right: 0;
}

.rotate90 {
  transform: rotate(90deg);
}

// _display
.d-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
}

.display-none {
  display: none;
}

.display-list-item {
  display: list-item;
}

.display-contents {
  display: contents;
}

.visibility-hidden {
  visibility: hidden;
}

//overflow
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

//text
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

//font
.font-small {
  // DO NOT use this class!
  font-size: 12px;
}

.bold {
  // DO NOT use this class!
  font-weight: 800;
}

.normal {
  // DO NOT use this class!
  font-weight: normal;
}

.italic {
  // DO NOT use this class!
  font-style: italic;
}

.select-none {
  user-select: none;
}

$percentage-list: 0 5 10 15 20 25 30 35 40 45 48 50 55 60 65 70 75 80 85 90 95
  100;

// height

@each $height in $percentage-list {
  .height-vh-#{$height} {
    height: #{$height}vh;
  }
}

@each $height in $percentage-list {
  .max-height-vh-#{$height} {
    max-height: #{$height}vh;
  }
}

@each $height in $percentage-list {
  .min-height-vh-#{$height} {
    min-height: #{$height}vh;
  }
}

//width

@each $percentage in $percentage-list {
  .width-#{$percentage} {
    width: round(percentage(calc($percentage/100)));
  }
}

@each $nbr in $percentage-list {
  .width-#{$nbr}-px {
    width: #{$nbr}px;
  }
}

@each $percentage in $percentage-list {
  .max-width-#{$percentage} {
    max-width: round(percentage(calc($percentage/100)));
  }
}

@each $percentage in $percentage-list {
  .min-width-#{$percentage} {
    min-width: round(percentage(calc($percentage/100)));
  }
}

//opacity
@each $percentage in $percentage-list {
  .opacity-#{$percentage} {
    opacity: calc($percentage/100);
  }
}

//flex
@each $percentage in $percentage-list {
  .flex-#{$percentage} {
    flex: round(percentage(calc($percentage/100)));
  }
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.break-word-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.overflow-break {
  overflow-wrap: break-word;
}

.hasErrors {
  color: #f97150;
}

.rounded {
  border-radius: 50%;
}

.rounded-2 {
  border-radius: 2px;
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-6 {
  border-radius: 6px;
}

// shadows

.shadow-none {
  box-shadow: none !important;
}

.shadow-inset-sm {
  box-shadow: inset 0 0 1px 0 rgb(0 0 0 / 25%);
}

.shadow-sm {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
}

.shadow-all {
  box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 30%);
}

.shadow-top {
  box-shadow: 0 -2px 1px -1px rgb(0 0 0 / 30%);
}

.shadow-modal {
  box-shadow: 0 4px 6px #707d84;
}

//Borders

.table-row-border {
  border-top: solid 1px #e8e8e8;

  &:last-child {
    border-bottom: solid 1px #e8e8e8;
  }
}

$borders: 5;

@mixin borders($preffix, $suffix: '') {
  @for $i from 0 through $borders {
    @if $suffix == '' {
      .#{$preffix}-#{$i} {
        border: #{$i}px solid $color__grey-light;
      }
    } @else {
      .#{$preffix}-#{$i}-#{$suffix} {
        #{$preffix}-#{$suffix}: #{$i}px solid $color__grey-light;
      }
    }
  }
}

@include borders('border');
@include borders('border', 'left');
@include borders('border', 'right');
@include borders('border', 'top');
@include borders('border', 'bottom');

// Spacing
$paddings: 15;

@mixin class-list($preffix, $suffix: '') {
  @for $i from 0 through $paddings {
    @if $suffix == '' {
      .#{$preffix}-#{$i} {
        #{$preffix}: #{calc($i/4)}rem !important;
      }
    } @else {
      .#{$preffix}-#{$i}-#{$suffix} {
        #{$preffix}-#{$suffix}: #{calc($i/4)}rem !important;
      }
    }
  }
}

@include class-list('padding');
@include class-list('padding', 'left');
@include class-list('padding', 'right');
@include class-list('padding', 'top');
@include class-list('padding', 'bottom');
@include class-list('margin');
@include class-list('margin', 'left');
@include class-list('margin', 'right');
@include class-list('margin', 'top');
@include class-list('margin', 'bottom');
@include class-list('gap');

.margin-auto-left {
  margin-left: auto;
}

.margin-auto-right {
  margin-right: auto;
}

.margin-auto-top {
  margin-top: auto;
}

.margin-auto-bottom {
  margin-bottom: auto;
}

.margin-auto-top {
  margin-top: auto;
}

.margin-auto {
  margin: auto;
}

// order
$order-list: 1 2 3 4 5;

@each $order in $order-list {
  .order-#{$order} {
    order: $order;
  }
}

// new font system

// Poppins

.font__superBigTitle {
  @include font__superBigTitleMixin;
}

.font__bigTitle {
  @include font__bigTitleMixin;
}

.font__title {
  @include font__titleMixin;
}

// Nunito

.font__headlineRegular {
  @include font__headlineRegularMixin;
}

.font__headlineHeavy {
  @include font__headlineHeavyMixin;
}

.font__bodyRegular {
  @include font__bodyRegularMixin;
}

.font__bodyHeavy {
  @include font__bodyHeavyMixin;
}

.font__smallRegular {
  @include font__smallRegularMixin;
}

.font__smallHeavy {
  @include font__smallHeavyMixin;
}

.font__xSmallRegular {
  @include font__xSmallRegularMixin;
}

.font__xSmallHeavy {
  @include font__xSmallHeavyMixin;
}

// Colors of the palette
.color__gray02 {
  color: $color__Gray-02;
}

.color__gray03 {
  color: $color__Gray-03;
}

.bg__gray06 {
  background: $color__Gray-06;
}

.stretched-link::after {
  position: absolute;
  inset: 0;
  z-index: 0;
  content: '';
}

// Form classes
.form-wrap > * {
  margin-bottom: 1rem;
}

.remove-native-style {
  all: unset;
}

//z-index
$z-index-list: (
  $zIndex__levelP0: 0,
  $zIndex__levelP1: 1,
  $zIndex__levelP2: 2,
  $zIndex__levelP3: 3,
  $zIndex__levelP4: 4,
  $zIndex__levelP5: 5,
  $zIndex__levelP6: 6,
  $zIndex__levelP7: 7,
  $zIndex__levelP8: 8,
  $zIndex__levelP9: 9,
  $zIndex__levelP10: 10,
);

@each $level, $index in $z-index-list {
  .zIndex__levelP#{$index} {
    z-index: $level;
  }
}
