@media not print {

  main,
  html,
  body {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    align-items: stretch;
  }
}
