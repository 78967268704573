$navbarHeight: 48px;
$kpi-height: 44px;
$color__primary: #60c587;
$color__secondary: rgb(87 71 81);
$border__grey: rgb(200 200 200);
$soft-blue: rgb(87 160 238);
$form-elements-blue: #0e75c2;
$color__focus: $soft-blue;
$color__white: #fff;
$color__black: #000;
$color__grey: #999;
$color__grey-3: #a8b5c0;
$color__grey-5: #dbe3e8;
$color__grey-7: #f5f9fa;
$color__grey-light: #e1e1e1;
$color__grey-dark: #d4d4d4;
$color__cork: #4f4b48;
$color__hurricane: rgb(224 227 227);
$color__space-shuttle: rgb(177 182 186);
$color__baby-pink: #f09490;
$color__baby-green: rgb(137 234 189);
$color__dew-green: rgb(238 252 246);
$color__kelly-green: #00c133;
$color__aero-blue: #b9e2ca;
$color__green-base: #2dbd45;
$color__red-base: #d3302c;
$color__blue-base: #2c9cf0;
$color__yellow-base: #f6b80c;
$color__purple-base: #9b46c2;
$color__turqoise-base: #27b7c2;
$color__violet-base: #6d42e0;
$color__pink-base: #da3399;
$color__teal-base: #2fe899;
$color__lime-base: #b3e21c;
$color__orange-base: #df7323;
$color__berry: #b23d59;
$color__pink: #f2d4d7;
$border-grey: rgb(200 200 200);
$button-grey: rgb(247 247 247);
$confirm-color: rgb(0 193 51);
$reject-color: rgb(198 50 43);
$warning-color: rgb(211 48 44);
$red-light: #fd4b46;
$dark-base: rgb(65 73 77);
$dark-lighter: lighten($dark-base, 20%);
$action-font-color: #fff;
$quiet-color: rgb(153 153 153);
$color__background: $color__white;
$zIndex__levelP0: 0;
$zIndex__levelP1: 9;
$zIndex__levelP2: 99;
$zIndex__levelP3: 999;
$zIndex__levelP4: 9999;
$zIndex__levelP5: 99999;
$zIndex__levelP6: 999999;
$zIndex__levelP7: 9999999;
$zIndex__levelP8: 99999999;
$zIndex__levelP9: 999999999;
$zIndex__levelP10: 1000000000;
$icon__size: 38px;
$spinner__size: 20px;
$background-color: rgb(255 255 255);
$touch-unit: 38px;
$xs-end: 576px;
$sm-end: 768px;
$md-end: 992px;
$lg-end: 1280px;
$xl-end: 1920px;
$spacer: 1rem;
$input-border: 1px $border__grey solid !default;
$input-border-hover: 1px $soft-blue solid !default;
$input-border-active: 1px $soft-blue solid !default;
$warning-font-color: #fff;
$warning-color-light: $red-light !default;
$detailpanel-width: 370px;
$detailpanel-width-s: 20px * 15;
$detailpanel-width-ellipsis: $detailpanel-width * 0.6;
$popup-layer: 900000;

// Breakpoints
@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: $md-end + 1) {
      @content;
    }
  }

  @if $point == laptop {
    @media screen and (max-width: $md-end) and (min-width: $sm-end + 1) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (max-width: $sm-end) and (min-width: $xs-end + 1) {
      @content;
    }
  } @else if $point == phone {
    @media screen and (max-width: $xs-end) {
      @content;
    }
  }
}

@mixin breakpoint-max($point) {
  @if $point == desktop {
    @media (max-width: $md-end + 1) {
      @content;
    }
  } @else if $point == laptop {
    @media screen and (max-width: $md-end) {
      @content;
    }
  } @else if $point == tablet {
    @media screen and (max-width: $sm-end) {
      @content;
    }
  } @else if $point == phone {
    @media screen and (max-width: $xs-end) {
      @content;
    }
  }
}

@function pow($number, $exp) {
  $value: 1;

  @if $exp > 0 {
    @for $i from 1 through $exp {
      $value: $value * $number;
    }
  } @else if $exp < 0 {
    @for $i from 1 through -$exp {
      $value: $value / $number;
    }
  }

  @return $value;
}

@function font-scale($value) {
  @return pow($font-scale-ratio, $value) * 15px;
}

// Font and spacing
$font-scale-ratio: 1.25;
$h1-size: font-scale(4);
$h2-size: font-scale(3);
$h3-size: font-scale(2);
$h4-size: font-scale(1);
$h5-size: font-scale(0);

@mixin font-size($font-size, $line-height: true) {
  font-size: (calc($font-size / 15px)) * 1rem;

  @if $line-height == true {
    line-height: $font-size *
      ceil(calc($font-size / 20px)) *
      (calc(20px / $font-size));
  }
}

// New color system
// exported from src/colors.js with signature "$color__Name-code"
// "-code" is optional

// New font system

// Poppins
$fontWeight__Title: 700;
$fontColor__Title: $color__Petrol-01;
$fontSize__SuperBigTitle: 40px;
$fontSize__BigTitle: 32px;
$fontSize__Title: 26px;
$fontLineHeight__SuperBigTitle: 56px;
$fontLineHeight__BigTitle: 48px;
$fontLineHeight__Title: 40px;

@mixin font__superBigTitleMixin($color: $fontColor__Title) {
  font-size: $fontSize__SuperBigTitle;
  font-weight: $fontWeight__Title;
  line-height: $fontLineHeight__SuperBigTitle;
  color: $color;
  font-family: Poppins, 'Avenir Next', 'Helvetica Neue', sans-serif;
}

@mixin font__bigTitleMixin($color: $fontColor__Title) {
  font-size: $fontSize__BigTitle;
  font-weight: $fontWeight__Title;
  line-height: $fontLineHeight__BigTitle;
  color: $color;
  font-family: Poppins, 'Avenir Next', 'Helvetica Neue', sans-serif;
}

@mixin font__titleMixin($color: $fontColor__Title) {
  font-size: $fontSize__Title;
  font-weight: $fontWeight__Title;
  line-height: $fontLineHeight__Title;
  color: $color;
  font-family: Poppins, 'Avenir Next', 'Helvetica Neue', sans-serif;
}

// Nunito

$fontWeight__Regular: 400;
$fontWeight__Heavy: 800;
$fontColor__Regular: $color__Gray-01;
$fontColor__Heavy: $color__Gray-00;
$fontSize__Headline: 20px;
$fontSize__Body: 16px;
$fontSize__Small: 14px;
$fontSize__XSmall: 12px;
$fontSize__Tiny: 10px;
$fontLineHeight__Headline: 28px;
$fontLineHeight__Body: 24px;
$fontLineHeight__Small: 20px;
$fontLineHeight__XSmall: 16px;
$fontLineHeight__Tiny: 14px;

@mixin font__headlineRegularMixin($color: $fontColor__Regular) {
  font-size: $fontSize__Headline;
  font-weight: $fontWeight__Regular;
  line-height: $fontLineHeight__Headline;
  color: $color;
}

@mixin font__headlineHeavyMixin($color: $fontColor__Heavy) {
  font-size: $fontSize__Headline;
  font-weight: $fontWeight__Heavy;
  line-height: $fontLineHeight__Headline;
  color: $color;
}

@mixin font__bodyRegularMixin($color: $fontColor__Regular) {
  font-size: $fontSize__Body;
  font-weight: $fontWeight__Regular;
  line-height: $fontLineHeight__Body;
  color: $color;
}

@mixin font__bodyHeavyMixin($color: $fontColor__Heavy) {
  font-size: $fontSize__Body;
  font-weight: $fontWeight__Heavy;
  line-height: $fontLineHeight__Body;
  color: $color;
}

@mixin font__smallRegularMixin($color: $fontColor__Regular) {
  font-size: $fontSize__Small;
  font-weight: $fontWeight__Regular;
  line-height: $fontLineHeight__Small;
  color: $color;
}

@mixin font__smallHeavyMixin($color: $fontColor__Heavy) {
  font-size: $fontSize__Small;
  font-weight: $fontWeight__Heavy;
  line-height: $fontLineHeight__Small;
  color: $color;
}

@mixin font__xSmallRegularMixin($color: $fontColor__Regular) {
  font-size: $fontSize__XSmall;
  font-weight: $fontWeight__Regular;
  line-height: $fontLineHeight__XSmall;
  color: $color;
}

@mixin font__xSmallHeavyMixin($color: $fontColor__Heavy) {
  font-size: $fontSize__XSmall;
  font-weight: $fontWeight__Heavy;
  line-height: $fontLineHeight__XSmall;
  color: $color;
}
