@media print {
  :root {
    body {
      width: auto !important;
      height: auto;
      overflow: visible;
    }

    #app-container,
    #app-container>div,
    .appview,
    .app__main-content,
    .main-content {
      background-color: #fff;
      flex: 0 1 auto;
      width: auto !important;
      height: auto;
      overflow: visible;
    }

    .nav-menu-wrapper,
    .clipboard-box {
      display: none !important;
    }

    .top-bar,
    .ReactQueryDevtools {
      display: none;
    }

    .more_options {
      display: none;
    }

    .topbar--schedule,
    .schedule-container {
      display: none !important;
    }

    .scheduler-employee-agreement__hours {
      font-size: 0.77em !important;

      &>span {
        font-size: inherit;
      }
    }

    .intercom-lightweight-app,
    .intercomMaximised {
      display: none !important;
    }

    .react-tooltip-lite,
    .detail-panel {
      display: none !important;
    }

    .print-bullet {
      width: 0.15em;
      height: 0.15em;
      background-color: currentcolor;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      align-self: center;
      flex-shrink: 0;
    }

    .avatar__image {
      font-size: inherit !important;

      $avatarSize: 2.2em;

      width: $avatarSize !important;
      height: $avatarSize !important;

      [style*='width'] {
        width: $avatarSize !important;
        height: $avatarSize !important;
      }
    }

    .scheduler-employee-item {
      padding: 0 !important;

      .padding-2-right {
        padding-right: 0.3em !important;
      }

      .margin-1-right {
        margin-right: 0.25em !important;
      }

      .color-container {
        width: 0.75em !important;
        height: 0.75em !important;
      }
    }

    .scheduler-employee-agreement__hours {
      display: none !important;
    }

    .scheduler-employee-item__name {
      font-size: inherit !important;
      line-height: 120% !important;
      text-overflow: initial !important;
      white-space: initial !important;
    }

    .badge-default {
      font-size: inherit !important;
      line-height: inherit !important;
    }

    .topBar__titleButton {
      display: none !important;
    }

    .baseSchedule__topbar {
      display: none !important;
    }

    .ready-for-payroll__avatar-wrapper {
      display: none !important;
    }

    .sideDrawer {
      display: block;
    }

    .sideDrawer__content {
      display: none;
    }

    .sideDrawer__children {
      display: block;
      box-shadow: none;
    }
  }
}