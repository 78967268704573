@import 'variables';

@media not print {

  html,
  body {
    height: 100%;
  }

  html {
    @include font__bodyRegularMixin;
  }

  body {
    margin: 0;
    padding: 0;
    color: $dark-base;
    overflow: hidden;
  }

  .authenticated {
    background-color: #f7f7f7;
  }

  * {
    -webkit-tap-highlight-color: transparent;
    outline: none;
  }

  #app-container {
    height: 100%;
    overflow-x: hidden;
  }

  body,
  button {
    color: $dark-base;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  }

  // TODO: Fix CSS selection
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  ::selection {
    background: lighten(rgb(44 156 240), 40%);
    color: inherit;
  }

  ::selection {
    background: mix(rgb(44 156 240), $dark-base, 75%);
    color: $background-color;
  }

  p {
    padding-bottom: 20px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  text-rendering: optimizelegibility;
  padding: 10px 0;
  user-select: text;
}

h1,
h2 {
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h3 {
  font-weight: 800;
}

h4,
h5,
h6 {
  font-weight: 800;
}

h1 {
  @include font-size($h1-size);
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  @include font-size($h4-size, false);

  line-height: 20px;
}

h5 {
  @include font-size($h5-size, false);

  line-height: 20px;
}

textarea {
  font-size: 15px;
  font-weight: 200;
  line-height: 24px;
  color: #565a5c;
  background-color: #fff;
  padding: 6px;
  border-radius: 4px;
  border: 1px #c8c8c8 solid;
}

div[data-radix-popper-content-wrapper] {
  z-index: $zIndex__levelP6 !important;
}
